// Функция для авторизации пользователя
import {API_URL} from "./config";

window.apiRequest = async function(url, options = {}) {
    const token = localStorage.getItem('authToken');
    options.headers = options.headers || {};
    if (options.headers['Content-Type'] !== 'multipart/form-data') { // Исключаем для файловых запросов
        options.headers['Content-Type'] = 'application/json';
    }
    if (token) {
        options.headers['Authorization'] = `Token ${token}`;
    }
    return fetch(url, options);
};

export async function loginUser(email, password) {
    try {
        const response = await fetch(`${API_URL}/api/auth/token/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });

        if (response.ok) {
            const data = await response.json();
            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, errors: errorData };
        }
    } catch (error) {
        console.error('Ошибка при авторизации:', error);
        return { success: false, errors: { detail: 'Произошла ошибка при авторизации.' } };
    }
}

// Функция для регистрации нового пользователя
export async function registerUser(email, username, firstName, lastName, password) {
    try {
        const response = await fetch(`${API_URL}/api/users/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, username, first_name: firstName, last_name: lastName, password })
        });

        if (response.ok) {
            const data = await response.json();
            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, errors: errorData };
        }
    } catch (error) {
        console.error('Ошибка при регистрации:', error);
        return { success: false, errors: { detail: 'Произошла ошибка при регистрации.' } };
    }
}

export async function getUserProfile() {
    try {
        const response = await apiRequest(`${API_URL}/api/users/me/`, {
            method: 'GET'
        });

        if (response.ok) {
            const data = await response.json();
            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, errors: errorData };
        }
    } catch (error) {
        console.error('Ошибка при получении профиля:', error);
        return { success: false, errors: { detail: 'Произошла ошибка при получении профиля.' } };
    }
}

// Функция для обновления информации о профиле пользователя
export async function updateUserProfile(updatedData) {
    try {
        const response = await apiRequest(`${API_URL}/api/users/me/`, {
            method: 'PUT',
            body: JSON.stringify(updatedData)
        });

        if (response.ok) {
            const data = await response.json();
            return { success: true, data };
        } else {
            const errorData = await response.json();
            return { success: false, errors: errorData };
        }
    } catch (error) {
        console.error('Ошибка при обновлении профиля:', error);
        return { success: false, errors: { detail: 'Произошла ошибка при обновлении профиля.' } };
    }
}
