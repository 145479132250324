export function initializeIconSelectWidget(iconList, onSelectCallback, selectedMarkerTypeName) {
    const widget = document.getElementById('icon-select-widget');
    if (!widget) {
        console.error('Icon select widget container not found.');
        return;
    }

    const selectedIconElement = widget.querySelector('.selected-icon');
    const iconOptions = widget.querySelector('.icon-options');

    // Функция для создания опций иконок
    function populateIconOptions() {
        iconOptions.innerHTML = ''; // Очищаем существующие опции

        // Добавляем пустую иконку
        const emptyIcon = {
            name: 'Нет иконки' // Можете изменить текст на свой
        };
        createIconOption(emptyIcon);

        // Добавляем остальные иконки
        iconList.forEach(icon => createIconOption(icon));
    }

    // Функция для создания опции иконки
    function createIconOption(icon) {
        const option = document.createElement('div');
        option.classList.add('icon-radio-option');
        option.dataset.iconId = icon.id;

        if (icon.logo) {
            const img = document.createElement('img');
            img.src = icon.logo;
            img.alt = icon.name;
            img.title = icon.name;
            option.appendChild(img);
        } else {
            const span = document.createElement('span');
            span.textContent = icon.name;
            span.classList.add('empty-icon'); // Добавляем класс для пустой иконки, если нужно
            option.appendChild(span);
        }

        // Если эта иконка выбрана, отображаем её
        if (icon.name === selectedMarkerTypeName) {
            console.log('selected');
            updateSelectedIcon(icon);
        }

        iconOptions.appendChild(option);

        // Обработчик клика по опции
        option.addEventListener('click', () => {
            updateSelectedIcon(icon);
            iconOptions.classList.remove('show'); // Закрываем опции после выбора
            if (typeof onSelectCallback === 'function') {
                onSelectCallback(icon);
            }
        });
    }

    // Функция для обновления выбранной иконки
    function updateSelectedIcon(icon) {
        selectedIconElement.innerHTML = ''; // Очищаем текущую иконку
        if (icon.logo) {
            const img = document.createElement('img');
            img.src = icon.logo;
            img.alt = icon.name;
            img.width = 50;
            img.height = 50;
            selectedIconElement.appendChild(img); // Добавляем новое изображение
        } else {
            const span = document.createElement('span');
            span.classList.add('empty-icon');
            span.textContent = 'Нет иконки'; // Или пустой текст
            selectedIconElement.appendChild(span); // Если иконка отсутствует, добавляем пустую иконку
        }
    }

    // Инициализация виджета
    populateIconOptions();

    // Убедитесь, что опции скрыты по умолчанию
    iconOptions.classList.remove('show'); // Скрываем опции при инициализации

    // Обработчик клика по выбранной иконке для открытия/закрытия опций
    selectedIconElement.addEventListener('click', () => {
        iconOptions.classList.toggle('show'); // Переключаем видимость опций
    });

    // Закрытие опций при клике вне виджета
    document.addEventListener('click', (event) => {
        if (!widget.contains(event.target)) {
            iconOptions.classList.remove('show'); // Закрываем опции, если клик вне виджета
        }
    });
}
