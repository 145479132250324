import {getMarkerTypes} from './api/markerTypes';


// Функция для загрузки и добавления изображений маркера
function loadAndAddImage(url, imageName, map, callback) {
    map.loadImage(url, (error, image) => {
        if (error) {
            console.error(`Ошибка загрузки изображения маркера ${imageName}:`, error);
            throw error;
        }
        map.addImage(imageName, image);
        if (callback) callback();  // Вызываем коллбек, если он есть
    });
}

// Функция для последовательной загрузки всех изображений
export function loadAllMapImages_old(map, callback) {
    loadAndAddImage(
        'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png', 'custom-marker',
        map, () => {
            loadAndAddImage('https://i.imgur.com/kiw0dR2.png', 'second-marker', map, callback);
        });
}

export async function loadAllMapImages(map, callback) {
    try {
        const markerTypes = await getMarkerTypes();
        const loadImagesPromises = markerTypes.map(markerType => {
            if (markerType.logo) {
                const imageName = markerType.name;
                // Обеспечиваем использование HTTPS для URL изображений
                // TODO пока костыльно, но вот так))))
                let imageUrl = markerType.logo;
                if (markerType.logo.startsWith('http://')) {
                    imageUrl = markerType.logo.replace('http://', 'https://');
                } else if (!markerType.logo.startsWith('https://')) {
                    imageUrl = `${markerType.logo}`;
                    // Дополнительно можно проверить и добавить 'https://' если необходимо
                    if (!imageUrl.startsWith('https://')) {
                        imageUrl = `https://${imageUrl}`;
                    }
                }
                return new Promise((resolve, reject) => {
                    map.loadImage(imageUrl, (error, image) => {
                        if (error) {
                            console.error(`Ошибка загрузки изображения маркера ${imageName}:`, error);
                            reject(error);
                        } else {
                            map.addImage(imageName, image);
                            resolve();
                        }
                    });
                });
            }
            return Promise.resolve();
        });

        // Ожидаем загрузку всех изображений маркеров
        await Promise.all(loadImagesPromises);

        if (callback) callback();

    } catch (error) {
        console.error('Ошибка при загрузке всех изображений маркера:', error);
    }
}
