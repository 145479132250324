// Функция для удаления изображения по ID через API
import {API_ADMIN_TOKEN, API_URL} from "./config";


export async function deleteImages(imageIds, featureId) {
    const deleteUrl = `${API_URL}/api/points/${featureId}/images/`;

    try {
        const response = await apiRequest(deleteUrl, {
            method: 'DELETE',
            body: JSON.stringify({
                images_to_delete: imageIds  // Передаем массив ID изображений для удаления
            }),
        });

        if (!response.ok) {
            throw new Error(`Не удалось удалить изображения: ${response.statusText}`);
        }

        console.log(`Изображения с ID ${imageIds.join(', ')} успешно удалены.`);
    } catch (error) {
        console.error(`Ошибка при удалении изображений:`, error);
        alert(`Произошла ошибка при удалении изображений.`);
    }
}

