import {API_ADMIN_TOKEN, API_URL} from './config';


/**
 * Функция для загрузки всех точек
 * @returns {Promise<Object>} - GeoJSON FeatureCollection
 */
export async function fetchAllPoints() {

    const url = `${API_URL}/api/feature/collection/`;

    try {
        const response = await apiRequest(url, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error(`Ошибка сети: ${response.status} ${response.statusText}`);
        }

        const points = await response.json(); // Массив фич

        return {
            type: 'FeatureCollection',
            features: points,
        };
    } catch (error) {
        console.error('Ошибка при загрузке точек:', error);
        return {
            type: 'FeatureCollection',
            features: [],
        };
    }
}

export async function getDefaultWorkingHours() {
    try {
        const response = await apiRequest(`${API_URL}/api/working_hours/default/`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Ошибка при получении часов работы по умолчанию');
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        return null;
    }
}


export function clearPolygon() {
    console.log('clearPolygon');
    if (map.getLayer('polygon-layer')) {
        map.removeLayer('polygon-layer');
    }
    if (map.getSource('polygon')) {
        map.removeSource('polygon');
    }
}

// Функция для отображения существующего полигона
export function displayPolygon(polygonCoordinates) {
    if (polygonCoordinates && polygonCoordinates.length > 0) {
        // Создаем geoJSON объект для полигона
        const polygonGeoJSON = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: polygonCoordinates
            }
        };

        // Добавляем источник и слой на карту
        if (map.getSource('polygon')) {
            // Если полигон уже существует на карте, обновляем его координаты
            map.getSource('polygon').setData(polygonGeoJSON);
        } else {
            // Добавляем новый источник и слой
            map.addSource('polygon', {
                type: 'geojson',
                data: polygonGeoJSON
            });

            map.addLayer({
                id: 'polygon-layer',
                type: 'fill',
                source: 'polygon',
                layout: {},
                paint: {
                    'fill-color': '#888888',
                    'fill-opacity': 0.4
                }
            });
        }
    }
}


export async function populatePoint(pointId) {
    try {
        const response = await apiRequest(`${API_URL}/api/points/${pointId}/`);


        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        const point = await response.json();

        if (point.polygon && point.polygon.coordinates) {
            displayPolygon(point.polygon.coordinates);
        }

        return point; // Return the fetched event data

    } catch (error) {
        console.error('Ошибка загрузки события:', error);
    }
}


// Функция для создания Point'а
export async function createPoint(pointData) {
    const url = `${API_URL}/api/points/`;

    const workingHoursData = {};
    ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
        const openTime = document.getElementById(`${day}-open`).value;
        const closeTime = document.getElementById(`${day}-close`).value;

        workingHoursData[`${day}_open`] = openTime === '' ? null : openTime;
        workingHoursData[`${day}_close`] = closeTime === '' ? null : closeTime;
        workingHoursData[`is_${day}_24_hours`] = document.getElementById(`${day}-24-hours`).checked;
        workingHoursData[`is_${day}_closed`] = document.getElementById(`${day}-closed`).checked;
    });


    // Создаем объект точки, который нужно отправить
    const pointPayload = {
        properties: {
            name: pointData.name,
            description: pointData.description,
            short_description: pointData.shortDescription,
            json_description: pointData.jsonDescription,
            address: pointData.address,
            category: pointData.category,
            subcategory: pointData.subcategory,
            contact_info: {
                phone_numbers: pointData.contactInfo.phoneNumbers || [],
                whatsapp_urls: pointData.contactInfo.whatsappUrls || [],
                viber_urls: pointData.contactInfo.viberUrls || [],
                telegram_urls: pointData.contactInfo.telegramUrls || [],
                instagram_urls: pointData.contactInfo.instagramUrls || [],
                facebook_urls: pointData.contactInfo.facebookUrls || [],
                emails: pointData.contactInfo.email || [],
                menu_url: pointData.contactInfo.menu || [],
                tripadvisor_urls: pointData.contactInfo.tripadvisorUrls || [],
                line_urls: pointData.contactInfo.lineUrls || [],
                websites: pointData.contactInfo.websites || [],
            },
            working_hours: workingHoursData,
            price_list: pointData.priceList,
            tags: pointData.tags,
            zoom: pointData.zoom,
            google_maps_url: pointData.googleMapsUrls
        },
        geometry: {
            type: "Point",
            coordinates: pointData.coordinates,
        }
    };

    if (pointData.markerType !== 'custom-marker') {
        pointPayload.properties.marker_type = pointData.markerType;
    }

    if (pointData.polygon) {
        pointPayload.polygon = {
            type: "Polygon",
            coordinates: pointData.polygon
        };
    }


    try {
        // Шаг 1: Создаем точку
        const response = await apiRequest(url, {
            method: 'POST',
            body: JSON.stringify(pointPayload),
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }

        const responseData = await response.json();
        const pointId = responseData.properties.id;

        // Шаг 2: Загружаем логотип, если он есть
        if (pointData.logo) {
            const logoFormData = new FormData();
            logoFormData.append('logo', pointData.logo);

            const logoResponse = await apiRequest(`${API_URL}/api/points/${pointId}/logo/`, {
                method: 'PATCH',
                body: logoFormData,
            });

            if (!logoResponse.ok) {
                throw new Error('Ошибка при загрузке логотипа: ' + logoResponse.statusText);
            }

            const logoResponseData = await logoResponse.json();
            console.log('Логотип успешно загружен:', logoResponseData);
        }

        // Шаг 3: Загружаем изображения, если они есть
        if (pointData.images.length > 0) {
            const imagesFormData = new FormData();
            pointData.images.forEach((image) => {
                imagesFormData.append('images', image); // добавляем файл изображения
            });

            const imagesResponse = await apiRequest(`${API_URL}/api/points/${pointId}/images/`, {
                method: 'POST',
                body: imagesFormData,
            });

            if (!imagesResponse.ok) {
                throw new Error('Ошибка при загрузке изображений: ' + imagesResponse.statusText);
            }

            const imagesResponseData = await imagesResponse.json();
            console.log('Изображения успешно загружены:', imagesResponseData);
        }
    } catch (error) {
        console.error('Ошибка при создании точки:', error);
    }
}


/**
 * Функция для обновления точки
 * @param {number|string} id - ID точки для обновления
 * @param {Object} pointData - Данные точки для обновления
 * @returns {Promise<Object>} - Обновленная точка
 */
export async function updatePoint(id, pointData) {
    const url = `${API_URL}/api/points/${id}/`;

    const workingHoursData = {};
    ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
        const openTime = document.getElementById(`${day}-open`).value;
        const closeTime = document.getElementById(`${day}-close`).value;

        workingHoursData[`${day}_open`] = openTime === '' ? null : openTime;
        workingHoursData[`${day}_close`] = closeTime === '' ? null : closeTime;
        workingHoursData[`is_${day}_24_hours`] = document.getElementById(`${day}-24-hours`).checked;
        workingHoursData[`is_${day}_closed`] = document.getElementById(`${day}-closed`).checked;
    });

    const pointPayload = {
        properties: {
            name: pointData.name,
            description: pointData.description,
            short_description: pointData.shortDescription,
            json_description: pointData.jsonDescription,
            address: pointData.address,
            marker_type: pointData.markerType,
            category: pointData.category,
            subcategory: pointData.subcategory,
            google_maps_url: pointData.googleMapsUrls,
            contact_info: {
                phone_numbers: pointData.contactInfo.phoneNumbers || [],
                whatsapp_urls: pointData.contactInfo.whatsappUrls || [],
                viber_urls: pointData.contactInfo.viberUrls || [],
                telegram_urls: pointData.contactInfo.telegramUrls || [],
                instagram_urls: pointData.contactInfo.instagramUrls || [],
                facebook_urls: pointData.contactInfo.facebookUrls || [],
                emails: pointData.contactInfo.email || [],
                menu_url: pointData.contactInfo.menu || [],
                tripadvisor_urls: pointData.contactInfo.tripadvisorUrls || [],
                line_urls: pointData.contactInfo.lineUrls || [],
                websites: pointData.contactInfo.websites || [],
            },
            working_hours: workingHoursData,
            price_list: pointData.priceList,
            tags: pointData.tags,
            zoom: pointData.zoom,
        },
        geometry: {
            type: "Point",
            coordinates: pointData.coordinates,
        }
    };

    if (pointData.polygon) {
        pointPayload.polygon = {
            type: "Polygon",
            coordinates: pointData.polygon
        };
    }

    try {
        // Шаг 1: Обновляем данные точки
        const response = await apiRequest(url, {
            method: 'PATCH',
            body: JSON.stringify(pointPayload),
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }

        const responseData = await response.json();
        const pointId = responseData.properties.id;

        if (pointData.logo) {
            const logoFormData = new FormData();
            logoFormData.append('logo', pointData.logo);

            const logoResponse = await apiRequest(`${API_URL}/api/points/${pointId}/logo/`, {
                method: 'PATCH',
                body: logoFormData,
            });

            if (!logoResponse.ok) {
                throw new Error('Ошибка при загрузке логотипа: ' + logoResponse.statusText);
            }

            const logoResponseData = await logoResponse.json();
            console.log('Логотип успешно загружен:', logoResponseData);
        }

        if (pointData.images.length > 0) {
            const imagesFormData = new FormData();
            pointData.images.forEach((image) => {
                imagesFormData.append('images', image); // добавляем файл изображения
            });

            const imagesResponse = await apiRequest(`${API_URL}/api/points/${pointId}/images/`, {
                method: 'POST',
                body: imagesFormData,
            });

            if (!imagesResponse.ok) {
                throw new Error('Ошибка при загрузке изображений: ' + imagesResponse.statusText);
            }

            const imagesResponseData = await imagesResponse.json();
            console.log('Изображения успешно загружены:', imagesResponseData);
        }

        return responseData; // Возвращаем обновленную точку

    } catch (error) {
        console.error('Ошибка при обновлении точки:', error);
        throw error;
    }
}


/**
 * Функция для удаления точки
 * @param {number|string} id - ID точки для удаления
 */
export async function deletePoint(id) {

    const url = `${API_URL}/api/points/${id}/`;

    try {
        const response = await apiRequest(url, {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new Error('Ошибка сети: ' + response.statusText);
        }
        console.log(`Точка с ID ${id} удалена`);
    } catch (error) {
        console.error(`Ошибка удаления Точки ${id}:`, error);
        throw error;
    }
}