import { generateUniqueId } from './utils';
import { placeLayerId, updatePlacesLayerData } from "./layers";
import { allowAddingPoints, isMovingPoint } from "./openEditPoint";
import {addNewPlaceSingle, getPlacesData, loadPlacesData} from "./data";
import {showSidebar, setCurrentFeature, setClickSidebarContent, } from './../index';
import {updatePointsData, showEditForm} from "./interactive";


let mapClickHandler = null;

// Добавление обработчика клика по карте для создания новых точек
export function setupMapClickEvent(map) {
    if (mapClickHandler) {
        console.log('Удаляем существующий обработчик click');
        map.off('click', mapClickHandler);
    }

    if (!allowAddingPoints) {
        return;
    }

    if (isMovingPoint) {
        return;
    }

    const addedPoints = new Set(); // Для отслеживания добавленных точек

    mapClickHandler = async (e) => {
        // Если добавление точек запрещено, просто выходим из функци

        const features = map.queryRenderedFeatures(e.point, { layers: [placeLayerId] });

        if (features.length) {
            const clickedFeature = features[0];
            const frontId = clickedFeature.properties.frontId;

            if (addedPoints.has(frontId)) {
                setClickSidebarContent();
                showSidebar();
                alert('Выберите категорию точки');
                return;
            } else {
                updatePointsData(map);
                showEditForm(clickedFeature);
                return;
            }
        } else {
            try {
                const lngLat = e.lngLat;
                const coords = [lngLat.lng, lngLat.lat];
                const generatedId = generateUniqueId();

                const newFeature = {
                    type: 'Feature',
                    properties: {
                        id: null,
                        frontId: generatedId,
                        marker_type: 'custom-marker',
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: coords
                    }
                };

                setClickSidebarContent();
                setCurrentFeature(newFeature);
                showSidebar();

                const placesData = await getPlacesData();
                const newPlacesData = addNewPlaceSingle(placesData, newFeature);
                updatePlacesLayerData(map, newPlacesData);

                addedPoints.add(generatedId);
            } catch (error) {
                console.error('Ошибка при добавлении нового маркера:', error);
            }
        }
    };

    console.log('Добавляем новый обработчик click');
    map.on('click', mapClickHandler);
}


// Функция для создания новой точки по координатам и центрирования карты
// TODO думаю можно как то по итогу вынести это в верхнюю функцию, потому что принцип похожий
export async function createMarkerFromCoordinates(map, lngLatArray) {
    try {
        const coords = [parseFloat(lngLatArray[0]), parseFloat(lngLatArray[1])]; // Преобразуем строковые координаты в числа
        const generatedId = generateUniqueId();

        const newFeature = {
            type: 'Feature',
            properties: {
                id: null,
                frontId: generatedId,
                marker_type: 'custom-marker',
            },
            geometry: {
                type: 'Point',
                coordinates: coords
            }
        };

        // Показать боковую панель и форму для редактирования
        setClickSidebarContent();
        setCurrentFeature(newFeature);
        showSidebar();

        // Обновляем данные с новой точкой
        const placesData = await getPlacesData();
        const newPlacesData = addNewPlaceSingle(placesData, newFeature);
        updatePlacesLayerData(map, newPlacesData);

        // Центрируем карту на новых координатах
        map.flyTo({
            center: coords,
            zoom: 16, // Можно задать другой уровень увеличения
            speed: 1.2, // Скорость перемещения
            curve: 1 // Отклонение траектории
        });

        console.log('Новая точка добавлена по координатам:', coords);
    } catch (error) {
        console.error('Ошибка при добавлении нового маркера по координатам:', error);
    }
}
