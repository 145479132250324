import 'bootstrap/dist/css/bootstrap.css'; // Используйте минифицированную версию для продакшна
import 'bootstrap/dist/js/bootstrap.bundle.js'; // Используйте минифицированную версию для продакшна
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css'; // Импорт стилей Mapbox GL JS
import {mapConfig, savedStyleUrl} from './modules/config';
import {addFullscreenControl, addNavigationControl, addScaleControl} from './modules/controls';
import {
    addNewPlaces,
    getDefaultPlacesData,
    loadPlacesData,
    MARKER_TYPE_ZOOM_DEFAULT, savePlacesData
} from './modules/data';
import {addPlacesLayer, updatePlacesLayerData} from './modules/layers';
import {addInteractiveFunctions, showEditForm} from './modules/interactive';
import {createMarkerFromCoordinates, setupMapClickEvent} from './modules/events';
import {loadAllMapImages} from './modules/images';
import {
    createEvent,
    createEventType,
    deleteEvent,
    populateEventTypes,
    updateEvent
} from './modules/api/events';
import {createTag, getTags, populateTags, startFetchingTags, TAGS_UPDATE_INTERVAL} from './modules/api/tags';
import {populateCategories, populateFormCategories} from './modules/api/categories';
import {createPoint, updatePoint, deletePoint, getDefaultWorkingHours, clearPolygon} from "./modules/api/points";
import { displayStyles } from './styles.js';
import { initializeIconSelectWidget } from './modules/icon_select_widget.js';
import {getMarkerTypes} from './modules/api/markerTypes';
import './styles/icon_select_widget.css';
import './styles/dragAndDrop.css';
import {dmsToDecimal} from "./modules/utils";
import {initializeAuthAndProfileModals} from "./modules/authentication";
import {openEditPoint} from "./modules/openEditPoint"; // Импортируем только компонент Modal

// Инициализация карты
mapboxgl.accessToken = mapConfig.accessToken;


export const map = new mapboxgl.Map({
    container: mapConfig.containerId,
    style: savedStyleUrl, // Используем сохраненный стиль
    center: mapConfig.center,
    zoom: mapConfig.zoom
});

const savedMapState = JSON.parse(localStorage.getItem('mapState'));
if (savedMapState) {
    map.setCenter(savedMapState.coordinates);
    map.setZoom(savedMapState.zoom);
}

window.map = map;

window.apiRequest = async function(url, options = {}) {
    const token = localStorage.getItem('authToken');
    options.headers = options.headers || {};

    const isFormData = options.body instanceof FormData;

    if (!isFormData) {
        options.headers['Content-Type'] = 'application/json';
    }

    if (token) {
        options.headers['Authorization'] = `Token ${token}`;
    }

    return fetch(url, options);
};


// Хранение данных карты в глобальной переменной для доступа из функций
export let placesData = { features: [] };
export let currentFeature = null;
export let isMovingPoint = false;


export function setCurrentFeature(feature) {
    currentFeature = feature;
}

// const userId = 2; // Замените на фактический ID пользователя
export let iconList = []

// Инициализация при загрузке страницы

// Функция для установки контента в сайдбар
export async function setSidebarContent(contentHTML) {
    const sidebarContent = document.getElementById('sidebar-content');
    if (sidebarContent) {
        sidebarContent.innerHTML = contentHTML;
        displayStyles();
    }
}

// Функция для отображения сайдбара
export function showSidebar() {
    const sidebar = document.getElementById('sidebar');
    const toggleBtn = document.getElementById('toggle-sidebar');
    const profileBtn = document.getElementById('profile-btn');
    const adminToggleBtn = document.getElementById('admin-toggle-btn');
    if (sidebar && toggleBtn && adminToggleBtn) {
        sidebar.classList.remove('sidebar-hide');
        sidebar.classList.add('sidebar-show');
        toggleBtn.classList.remove('toggle-visible');
        toggleBtn.classList.add('toggle-hidden');
        profileBtn.classList.remove('toggle-visible');
        profileBtn.classList.add('toggle-hidden');
        adminToggleBtn.classList.remove('toggle-visible');
        adminToggleBtn.classList.add('toggle-hidden');
    }
}

// Функция для скрытия сайдбара
export function hideSidebar() {
    const sidebar = document.getElementById('sidebar');
    const profileBtn = document.getElementById('profile-btn');
    const toggleBtn = document.getElementById('toggle-sidebar');
    const adminToggleBtn = document.getElementById('admin-toggle-btn');
    if (sidebar && toggleBtn && adminToggleBtn) {
        sidebar.classList.remove('sidebar-show');
        sidebar.classList.add('sidebar-hide');
        toggleBtn.classList.remove('toggle-hidden');
        toggleBtn.classList.add('toggle-visible');
        profileBtn.classList.remove('toggle-hidden');
        profileBtn.classList.add('toggle-visible');
        adminToggleBtn.classList.remove('toggle-hidden');
        adminToggleBtn.classList.add('toggle-visible');
    }
}

// Функция для загрузки категорий меню
export async function loadCategoriesMenu() {
    console.log("loadCategoriesMenu вызвана");

    // Загружаем категории с сервера
    const categories = await populateCategories();

    // Генерируем HTML для меню категорий
    let categoriesHTML = '<h2>Категории</h2><div class="categories-menu">';

    categories.forEach(category => {
        if (!category.logo || !category.logo.logo) {
            // Если логотип отсутствует, выводим сообщение
            alert(`У категории "${category.name}" нет логотипа.`);
            categoriesHTML += `
                <button class="btn btn-secondary category-btn" data-category="${category.name}">
                    <span>${category.name} (Без логотипа)</span>
                </button>
            `;
        } else {
            categoriesHTML += `
                <button class="btn btn-secondary category-btn" data-category="${category.name}">
                    <img src="${category.logo.logo}" class="category-logo"> ${category.name}
                </button>
            `;
        }
    });

    categoriesHTML += '</div>';

    // Вставляем HTML в боковую панель
    setSidebarContent(categoriesHTML);
    showSidebar();

    // Добавляем обработчики событий для кнопок категорий
    const categoryButtons = document.querySelectorAll('.category-btn');
    categoryButtons.forEach(button => {
        button.addEventListener('click', () => {
            const categoryName = button.getAttribute('data-category');
            console.log(`Категория выбрана: ${categoryName}`); // Отладочное сообщение
            loadSubCategories(categoryName, categories);
        });
    });
}



// Функция для загрузки подкатегорий
async function loadSubCategories(categoryName, categories) {
    console.log(`Загрузка подкатегорий для категории: ${categoryName}`);

    // Находим нужную категорию по её ID
    console.log(categoryName)
    const selectedCategory = categories.find(category => category.name === categoryName);

    if (!selectedCategory) {
        console.error('Категория не найдена');
        return;
    }

    // Проверяем наличие подкатегорий
    if (selectedCategory.subcategories.length === 0) {
        console.warn('Нет подкатегорий для выбранной категории');
        setSidebarContent(`<p>Нет подкатегорий для выбранной категории.</p>`);
        return;
    }

    // Генерация HTML для подкатегорий
    let subcategoriesHTML = `<h2>Подкатегории для ${selectedCategory.name}</h2><div class="subcategories-menu">`;

    selectedCategory.subcategories.forEach(subcategory => {
        const logoUrl = subcategory.logo?.logo;

        if (!logoUrl) {
            // Если логотип отсутствует, выводим предупреждение
            alert(`У подкатегории "${subcategory.name}" нет логотипа.`);
            // Используем логотип родительской категории как запасной вариант
            subcategoriesHTML += `
                <button class="btn btn-secondary subcategory-btn" data-subcategory="${subcategory.name}">
                    <img src="${selectedCategory.logo.logo}" class="category-logo"> ${subcategory.name} (Без логотипа)
                </button>
            `;
        } else {
            subcategoriesHTML += `
                <button class="btn btn-secondary subcategory-btn" data-subcategory="${subcategory.name}">
                    <img src="${logoUrl}" class="category-logo"> ${subcategory.name}
                </button>
            `;
        }
    });

    subcategoriesHTML += '</div>';

    // Вставляем HTML в боковую панель
    setSidebarContent(subcategoriesHTML);
    showSidebar();

    // Добавляем обработчики событий для кнопок подкатегорий
    const subcategoryButtons = document.querySelectorAll('.subcategory-btn');
    subcategoryButtons.forEach(button => {
        button.addEventListener('click', () => {
            const subcategoryName = button.getAttribute('data-subcategory');
            console.log(`Подкатегория выбрана: ${subcategoryName}`); // Отладочное сообщение
            currentFeature.properties.category = categoryName;
            currentFeature.properties.subcategory = subcategoryName;
            openEditPoint(currentFeature);
        });
    });
}


// TODO удалить и объединить в одну форму
export async function openEditEvent(feature) {
    const isEdit = feature.properties.id != null;
    const contactInfo = feature.properties.contact_info || {};
    console.log(feature)

    const formHTML = `
        <div class="container">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <h3>${isEdit ? 'Редактировать событие' : 'Создать событие'}</h3>
            </div>
            <form id="edit-marker-form" enctype="multipart/form-data">
                <div class="mb-3">
                    <label for="event-name" class="form-label">Назвау ние события</label>
                    <input type="text" class="form-control" id="event-name" required placeholder="Введите название события" 
                        value="${feature.properties.name || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="icon-select-widget" class="form-label">Иконка</label>
                    <div id="icon-select-widget" class="icon-select-widget">
                        <!-- Выбранная иконка -->
                        <div class="selected-icon">
                            <span>Выберите иконку</span>
                        </div>
                        <!-- Опции иконок будут загружены динамически -->
                        <div class="icon-options">
                            <!-- Опции будут добавлены через JavaScript -->
                        </div>
                    </div>
                </div>
<!--                <div class="mb-3">-->
<!--                    <label for="logo" class="form-label">Логотип события</label>-->
<!--                    <input type="file" class="form-control" id="logo">-->
<!--                </div>-->
<!--                TODO сделать картинки как в Point-->
                <div class="mb-3">
                    <label for="images" class="form-label">Изображения события</label>
                    <input type="file" class="form-control" id="images" multiple>
                </div>
                <div class="mb-3">
                    <label for="marker-description" class="form-label">Описание</label>
                    <textarea class="form-control" id="marker-description" rows="3">${feature.properties.description || ''}</textarea>
                </div>
                <div class="mb-3">
                <label for="short-description" class="form-label">Краткое описание</label>
                <input type="text" class="form-control" id="short-description" 
                    value="${feature.properties.short_description || ''}" placeholder="Введите краткое описание">
                </div>
                <div class="mb-3">
                    <label for="address" class="form-label">Адрес</label>
                    <input type="text" class="form-control" id="address" placeholder="Введите адрес" value="${feature.properties.address || ''}">
                </div>
                <div class="mb-3">
                    <label for="contact-number" class="form-label">Контактный номер</label>
                    <input type="text" class="form-control" id="contact-number" placeholder="Введите контактный номер" value="${(contactInfo.phone_numbers || [])[0] || ''}">
                </div>
                <div class="mb-3">
                    <label for="whatsapp" class="form-label">WhatsApp</label>
                    <input type="text" class="form-control" id="whatsapp" placeholder="Введите WhatsApp" value="${(contactInfo.whatsapp_urls || [])[0] || ''}">
                </div>
                <div class="mb-3">
                    <label for="viber" class="form-label">Viber</label>
                    <input type="text" class="form-control" id="viber" placeholder="Введите Viber" value="${(contactInfo.viber_urls || [])[0] || ''}">
                </div>
                <div class="mb-3">
                    <label for="telegram" class="form-label">Telegram</label>
                    <input type="text" class="form-control" id="telegram" placeholder="Введите Telegram" value="${(contactInfo.telegram_urls || [])[0] || ''}">
                </div>
                <div class="mb-3">
                    <label for="line" class="form-label">Line</label>
                    <input type="text" class="form-control" id="line" placeholder="Введите Line" value="${(contactInfo.line_urls || [])[0] || ''}">
                </div>
                <div class="mb-3">
                    <label for="website" class="form-label">Ссылка на сайт</label>
                    <input type="url" class="form-control" id="website" placeholder="Введите URL сайта" value="${(contactInfo.websites || [])[0] || ''}">
                </div>
                <div class="mb-3">
                    <label for="event-type" class="form-label">Тип события</label>
                    <select class="form-control" id="event-type">
                        <option value="">Выберите тип события</option>
                    </select>
                    <input type="text" class="form-control mt-2" id="new-event-type" placeholder="Введите тип события">
                    <button type="button" class="btn btn-secondary mt-2" id="add-event-type">Добавить тип</button>
                </div>
                
                <div class="mb-3 row">
                    <div class="col-md-6">
                        <label for="event-start" class="form-label">Начало события</label>
                        <input type="datetime-local" class="form-control" id="event-start" value="${feature.properties.start_dt ? new Date(feature.properties.start_dt).toISOString().slice(0, 16) : ''}">
                    </div>
                    <div class="col-md-6">
                        <label for="event-end" class="form-label">Окончание события</label>
                        <input type="datetime-local" class="form-control" id="event-end" value="${feature.properties.end_dt ? new Date(feature.properties.end_dt).toISOString().slice(0, 16) : ''}">
                    </div>
                </div>
                <div class="mb-3">
                    <label for="tags" class="form-label">Теги</label>
                    <select class="form-control" id="tags" multiple>
                        <!-- Добавить варианты тегов -->
                    </select>
                    <input type="text" class="form-control mt-2" id="new-tag" placeholder="Введите новый тег">
                    <button type="button" class="btn btn-secondary mt-2" id="add-tag">Добавить тег</button>
                </div>
                <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" id="notify-users" ${feature.properties.notification_enabled ? 'checked' : ''}>
                    <label class="form-check-label" for="notify-users">
                        Уведомление пользователям
                    </label>
                </div>
                <button type="submit" class="btn btn-primary">${isEdit ? 'Сохранить' : 'Добавить'}</button>
                ${isEdit ? `
                    <button type="button" class="btn btn-danger" id="delete-event-btn">Удалить</button>
                ` : ''}
            </form>
        </div>
    `;

    setSidebarContent(formHTML);
    showSidebar(); // Открываем сайдбар

    // Загружаем типы событий и заполняем select
    await populateEventTypes();
    const tags = await getTags();

    const eventTypeSelect = document.getElementById('event-type');
    const selectedType = feature.properties.type; // это имя типа события, например, "Движ"

// Найдите опцию, которая соответствует имени
    const optionToSelect = Array.from(eventTypeSelect.options).find(option => option.textContent === selectedType);
    if (optionToSelect) {
        eventTypeSelect.value = optionToSelect.value; // Установите значение по id
    } else {
        console.warn('Не найдено соответствие для типа события:', selectedType);
    }


    document.getElementById('add-event-type').addEventListener('click', async () => {
        const newEventTypeInput = document.getElementById('new-event-type');
        const newEventType = newEventTypeInput.value.trim();

        if (!newEventType) {
            alert('Пожалуйста, введите тип события.');
            return;
        }

        try {
            const createdEventType = await createEventType(newEventType); // Вызываем новую функцию

            const eventTypeSelect = document.getElementById('event-type');
            const option = document.createElement('option');
            option.value = createdEventType.id; // Предполагаем, что API возвращает id
            option.textContent = createdEventType.name; // И имя тега
            newEventTypeInput.value = ''; // Очищаем поле ввода

            alert('Тип события успешно добавлен.');
        } catch (error) {
            alert(`Произошла ошибка при добавлении типа события: ${error.message}`);
        }
    });


    document.getElementById('add-tag').addEventListener('click', async () => {
        const newTagInput = document.getElementById('new-tag');
        const newTag = newTagInput.value.trim();

        if (!newTag) {
            alert('Пожалуйста, введите тег.');
            return;
        }

        try {
            const createdTag = await createTag(newTag); // Вызываем новую функцию

            const tagsSelect = document.getElementById('tags');
            const option = document.createElement('option');
            option.value = createdTag.id; // Предполагаем, что API возвращает id
            option.textContent = createdTag.name; // И имя тега
            newTagInput.value = ''; // Очищаем поле ввода

            alert('Тег успешно добавлен.');
        } catch (error) {
            alert(`Произошла ошибка при добавлении тега: ${error.message}`);
        }
    });

    const selectedTags = feature.properties.tags || [];

    const tagsSelect = document.getElementById('tags');
    selectedTags.forEach(tagName => {
        const option = Array.from(tagsSelect.options).find(opt => opt.text === tagName);
        if (option) option.selected = true;
    });

    function handleIconSelect(icon) {
        console.log('Выбрана иконка:', icon);
        feature.properties.marker_type = icon.name;
    }

    initializeIconSelectWidget(iconList, handleIconSelect, feature.properties.marker_type);

    if (isEdit) {
        document.getElementById(
            'delete-event-btn'
        ).addEventListener('click', async () => {
            const eventId = feature.properties.id; // Предполагается, что `feature` доступен в данном контексте
            if (confirm('Вы уверены, что хотите удалить это событие?')) {
                try {
                    placesData.features = placesData.features.filter(feat => feat.properties.id !== eventId);
                    map.getSource('places').setData(placesData);

                    hideSidebar();
                    setDefaultSidebarContent();

                    alert('Событие успешно удалена.');

                    await deleteEvent(eventId);
                } catch (error) {
                    console.error('Ошибка при удалении события:', error);
                    alert('Произошла ошибка при удалении события.');
                }
            }
        });
    }

    // Добавляем обработчик отправки формы
    const editEvent = document.getElementById('edit-marker-form');
    if (editEvent) {
        editEvent.addEventListener('submit', async function (event) {
            event.preventDefault();

            // Собираем данные из формы
            const newName = document.getElementById('event-name').value.trim();
            const newDescription = document.getElementById('marker-description').value.trim();
            const shortDescription = document.getElementById('short-description').value;
            const newAddress = document.getElementById('address').value.trim();
            const newContactNumber = document.getElementById('contact-number').value.trim();
            const newWhatsApp = document.getElementById('whatsapp').value.trim();
            const newViber = document.getElementById('viber').value.trim();
            const newTelegram = document.getElementById('telegram').value.trim();
            const newLine = document.getElementById('line').value.trim();
            const eventTypeId = document.getElementById('event-type').value;
            const eventStart = document.getElementById('event-start').value;
            const eventEnd = document.getElementById('event-end').value;
            const newWebsite = document.getElementById('website').value.trim();
            const selectedTags = Array.from(document.getElementById('tags').selectedOptions).map(option => option.value);
            const notifyUsers = document.getElementById('notify-users').checked;

            // // Получаем файлы логотипа и изображений
            // const logoInput = document.getElementById('logo');
            // const logo = logoInput.files[0];

            const imageInput = document.getElementById('images');
            const images = Array.from(imageInput.files);

            // Проверка обязательных полей
            if (!newName || !newContactNumber || !eventStart || !eventEnd) {
                alert('Пожалуйста, заполните все обязательные поля.');
                return;
            }

            // Обновляем свойства фичи (если у тебя есть логика по обновлению карты)
            let featureUpdated = false;
            for (let feat of placesData.features) {
                feat.properties.name = newName;
                feat.properties.description = newDescription;
                feat.properties.short_description = shortDescription;
                feat.properties.address = newAddress;
                feat.properties.contact_number = newContactNumber;
                feat.properties.whatsapp = newWhatsApp;
                feat.properties.viber = newViber;
                feat.properties.telegram = newTelegram;
                feat.properties.line = newLine;
                feat.properties.event_type = eventTypeId;
                feat.properties.event_start = eventStart;
                feat.properties.event_end = eventEnd;
                feat.properties.website = newWebsite;
                feat.properties.tags = selectedTags;
                feat.properties.notify_users = notifyUsers;
                featureUpdated = true;
                break;
            }

            const updatedEventData = {
                name: newName,
                address: newAddress,
                description: newDescription,
                shortDescription: shortDescription,
                images: images, // Передаем массив изображений
                markerType: feature.properties.marker_type,
                eventType: eventTypeId,
                startDt: eventStart,
                endDt: eventEnd,
                notificationEnabled: notifyUsers,
                tags: selectedTags,
                contactInfo: {
                    phoneNumbers: [newContactNumber] ? [newContactNumber] : [],
                    whatsappUrls: newWhatsApp ? [newWhatsApp] : [],
                    viberUrls: newViber ? [newViber] : [],
                    telegramUrls: newTelegram ? [newTelegram] : [],
                    lineUrls: newLine ? [newLine] : [],
                    websites: newWebsite ? [newWebsite] : [],
                },
                coordinates: feature.geometry.coordinates,
            }

            if (!isEdit) {
                await createEvent(updatedEventData);
            } else {
                await updateEvent(feature.properties.id, updatedEventData);
            }

            placesData = await loadPlacesData();
            savePlacesData(placesData)
            updatePlacesLayerData(map, placesData);

            hideSidebar();
            setDefaultSidebarContent();
            alert(isEdit ? 'Событие успешно обновлено.' : 'Событие успешно создано.');
        });

    }
}


// Функция для загрузки начального контента в сайдбар
export function setDefaultSidebarContent() {
    clearPolygon();
    const sidebarHTML = `
        <h1>Главное меню</h1>
        <div class="main-menu">
            <h4>Доступные стили:</h4>
            <ul id="style-list">
            </ul>
        </div>
    `;
    setSidebarContent(sidebarHTML);
}

export function setClickSidebarContent() {
    clearPolygon();
    const sidebarHTML = `
        <div class="main-menu">
            <h4>Выберите тип: </h4>
            <button id="categories-btn" class="btn btn-secondary">
                <i class="bi bi-tags"></i> Точка
            </button>
<!--            TODO доработать когда событие будет сделано-->
            <button id="create-event-btn" class="btn btn-secondary">
                <i class="bi bi-calendar-plus"></i> Событие 
            </button>
        </div>
    `;
    setSidebarContent(sidebarHTML);
}

let isMapInitialized = false;


async function initializeMap() {
    loadAllMapImages(map, async () => {

        iconList = await getMarkerTypes();

        // Загружаем иконки
        iconList.forEach(icon => {
            if (!map.hasImage(icon.name)) {
                map.loadImage(icon.url, (error, image) => {
                    if (error) throw error;
                    map.addImage(icon.name, image);
                });
            }
        });
        startFetchingTags(TAGS_UPDATE_INTERVAL);

        placesData = await loadPlacesData(); // Загружаем данные
        savePlacesData(placesData);
        console.log('чисто загрузили точки с сервака', placesData);

        // Если данных нет, используем данные по умолчанию
        if (placesData.features.length === 0) {
            const defaultData = getDefaultPlacesData();
            addNewPlaces(placesData, defaultData);
            console.log('Используем данные по умолчанию:', defaultData);
        }

        addPlacesLayer(map, placesData);  // Добавление слоя с метками и кластеризацией
        console.log('Слой с местами добавлен на карту');

        // Активация кликов на карте для добавления новых меток
        setupMapClickEvent(map);
        // Добавление интерактивных функций
        addInteractiveFunctions(map);

        // Загрузка начального контента в сайдбар
        setDefaultSidebarContent();

        if (!isMapInitialized) {
            // Добавляем контролы на карту только один раз
            addNavigationControl(map);
            addScaleControl(map);
            addFullscreenControl(map);

            isMapInitialized = true;
        }
    });
}



// Выполнение логики после смены стиля карты
map.on('style.load', function() {
    initializeMap();
});

map.on('moveend', () => {
    const center = map.getCenter();
    const zoom = map.getZoom();
    const mapState = {
        coordinates: [center.lng, center.lat],
        zoom: zoom
    };
    localStorage.setItem('mapState', JSON.stringify(mapState));
});

const coordinatesInput = document.getElementById('coordinates-input'); // Поле для координат
const searchButton = document.getElementById('search-btn'); // Поле для координат
const addButton = document.getElementById('add-coordinates-btn'); // ID кнопки "Добавить"

coordinatesInput.addEventListener('keydown', function(event) {
    if (event.key === 'Enter') {
        if (event.ctrlKey || event.metaKey) {
            // Ctrl + Enter или Cmd + Enter: Добавить точку
            event.preventDefault();
            addPointFromCoordinates();
        } else {
            // Только Enter: Поиск точки
            event.preventDefault();
            searchPoint(coordinatesInput.value);
        }
    }
});

searchButton.addEventListener('click', function() {
    console.log('clock')
    console.log(coordinatesInput.value)
    searchPoint(coordinatesInput.value);
});

// Обработчик для кнопки "Добавить"
addButton.addEventListener('click', addPointFromCoordinates);

function searchPoint(query) {
    const trimmedQuery = query.trim();
    if (!trimmedQuery) {
        alert('Введите ID или название точки.');
        return;
    }

    let foundFeature = null;

    if (/^\d+$/.test(trimmedQuery)) {
        // Если введены только цифры, ищем по ID
        foundFeature = placesData.features.find(
            feature => feature.properties.id === parseInt(trimmedQuery)
        );
    }

    if (!foundFeature) {
        // Ищем по имени
        foundFeature = placesData.features.find(
            feature => feature.properties.name.toLowerCase() === trimmedQuery.toLowerCase()
        );
    }

    if (foundFeature) {
        // Перемещаем карту к найденной точке
        map.flyTo({
            center: foundFeature.geometry.coordinates,
            zoom: 15,
            essential: true
        });

        // Открываем сайдбар с информацией о точке
        showEditForm(foundFeature);
    } else {
        alert('Ничего не найдено');
    }
}

function addPointFromCoordinates() {
    const coordinates = coordinatesInput.value.split(','); // Разделяем строку по запятой
    let lng, lat;

    // Проверяем, является ли ввод DMS
    if (coordinates.length === 1) {
        // Пытаемся распознать DMS
        const dmsCoordinates = coordinatesInput.value.trim();
        const dmsParts = dmsCoordinates.split(/\s+/);
        if (dmsParts.length === 2) {
            lat = dmsToDecimal(dmsParts[0]);
            lng = dmsToDecimal(dmsParts[1]);
        }
    } else if (coordinates.length === 2) {
        // Обрабатываем ввод в формате "долгота, широта"
        lat = parseFloat(coordinates[0]?.trim());
        lng = parseFloat(coordinates[1]?.trim());
    }

    // Проверяем допустимость значений широты и долготы
    if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
        alert('Введите корректные координаты: широта должна быть между -90 и 90, долгота - между -180 и 180.');
        return;
    }

    if (lng !== undefined && lat !== undefined) {
        createMarkerFromCoordinates(map, [lng, lat]);
    } else {
        alert('Введите корректные координаты!');
    }
}


// Обработчики событий после загрузки DOM
document.addEventListener('DOMContentLoaded', function () {

    const sidebar = document.getElementById('sidebar');
    const toggleBtn = document.getElementById('toggle-sidebar');
    const profileBtn = document.getElementById('profile-btn');
    const mainCloseBtn = sidebar.querySelector('.btn-close');
    const adminPanelBtn = document.getElementById('admin-panel-btn');
    const adminToggleBtn = document.getElementById('admin-toggle-btn');

    // Обработчик для основной кнопки закрытия
    mainCloseBtn.addEventListener('click', function () {
        hideSidebar();
    });

    // Обработчик для кнопки toggle
    toggleBtn.addEventListener('click', function () {
        clearPolygon();
        showSidebar();
    });

    // Инициализация состояния кнопки toggle
    if (sidebar.classList.contains('sidebar-hide')) {
        toggleBtn.classList.add('toggle-visible');
        toggleBtn.classList.remove('toggle-hidden');
        adminToggleBtn.classList.add('toggle-visible');
        adminToggleBtn.classList.remove('toggle-hidden');
        profileBtn.classList.add('toggle-visible');
        profileBtn.classList.remove('toggle-hidden');
    } else {
        toggleBtn.classList.add('toggle-hidden');
        toggleBtn.classList.remove('toggle-visible');
        adminToggleBtn.classList.add('toggle-hidden');
        adminToggleBtn.classList.remove('toggle-visible');
        profileBtn.classList.add('toggle-hidden');
        profileBtn.classList.remove('toggle-visible');
    }

    // Добавление делегированного обработчика для всех кнопок внутри сайдбара
    sidebar.addEventListener('click', function (event) {
        console.log("Клик внутри сайдбара"); // Отладочное сообщение

        if (event.target.closest('.btn-close')) {
            console.log("Нажата кнопка закрытия"); // Отладочное сообщение
            hideSidebar();
        }

        if (event.target.closest('.btn-main-menu')) {
            console.log("Нажата кнопка главного меню"); // Отладочное сообщение
            setDefaultSidebarContent()
        }

        // Обработчики для кнопок главного меню
        if (event.target.id === 'categories-btn') {
            console.log("Нажата кнопка Категории"); // Отладочное сообщение
            loadCategoriesMenu();
        }

        if (event.target.id === 'create-event-btn') {
            // Логика для кнопки "Создание событий"
            openEditEvent(currentFeature);
        }
    });

    function openAdminPanel() {
        const newWindow = window.open('https://api.tap-map.net/admin/', '_blank');
        if (newWindow) {
            newWindow.focus(); // Фокусируемся на новой вкладке
        } else {
            alert("Ваш браузер заблокировал всплывающее окно. Пожалуйста, разрешите его для этого сайта.");
        }
        console.log('Переход в Админ панель');
    }

    if (adminToggleBtn) {
        adminToggleBtn.addEventListener('click', openAdminPanel);
    }

    if (adminPanelBtn) {
        adminPanelBtn.addEventListener('click', openAdminPanel);
    }
    initializeAuthAndProfileModals();
});
