import {
    hideSidebar,
    iconList,
    map,
    setDefaultSidebarContent,
    setSidebarContent,
    showSidebar,
    placesData
} from "../index";
import {setupMapClickEvent} from "./events";
import {clearPolygon, createPoint, deletePoint, getDefaultWorkingHours, updatePoint} from "./api/points";
import mapboxgl from "mapbox-gl";
import * as turf from '@turf/turf';
import {initializeIconSelectWidget} from "./icon_select_widget";
import {createTag, getTags} from "./api/tags";
import {populateCategories, populateFormCategories} from "./api/categories";
import {Modal} from "bootstrap";
import {createReview, displayReviews, updateReview} from "./api/reviews";
import {loadPlacesData, savePlacesData} from "./data";
import {updatePlacesLayerData} from "./layers";
import {deleteImages} from "./api/images";
import {API_URL} from "./api/config";


let draw;
let tempMarker = null;
let imagesToDelete = [];
let polygonDeleted = false;
let polygonModified = false;
export let allowAddingPoints = true;
export let isMovingPoint = false;

export async function openEditPoint(feature) {
    const isEdit = feature.properties.id != null;
    imagesToDelete = []

    // Генерация HTML формы
    const formHTML = generateFormHTML(feature, isEdit);

    // Установка содержимого боковой панели и отображение
    await setSidebarContent(formHTML);
    showSidebar();

    // Инициализация элементов
    initializeMapDrawing();
    initializeEventListeners(feature, isEdit);
    initializeIconWidget(feature);
    await populateFormData(feature);
}


function generateFormHTML(feature, isEdit) {
    const contactInfo = feature.properties.contact_info || {};
    const workingHours = feature.properties.working_hours || {};
    const imagesArray = feature.properties.images || [];
    const polygonExists = feature.polygon && feature.polygon.coordinates && feature.polygon.coordinates.length > 0;
    const MARKER_TYPE_ZOOM_DEFAULT = 1.0;
    let defaultWorkingHours = null;
    if (!isEdit && !Object.keys(workingHours).length) {
        defaultWorkingHours = getDefaultWorkingHours();
    }
    return `
        <div class="container">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <h3>${isEdit ? 'Редактировать точку' : 'Создать точку'}</h3>
            </div>
            <form id="edit-point-form" enctype="multipart/form-data">
            <div class="mb-3">
                <label for="category-select" class="form-label">Категория</label>
                <select class="form-control" id="category-select" required>
                    <option value="">Выберите категорию</option>
                    <!-- Options will be populated dynamically -->
                </select>
            </div>

                <div class="mb-3">
                    <label for="subcategory-select" class="form-label">Подкатегория</label>
                    <select class="form-control" id="subcategory-select" required>
                        <option value="">Выберите подкатегорию</option>
                        <!-- Options will be populated based on the selected category -->
                    </select>
                </div>
                <div class="mb-3">
                    <label for="point-name" class="form-label">Название точки</label>
                    <input type="text" class="form-control" id="point-name" required placeholder="Введите название точки" 
                        value="${feature.properties.name || ''}"
                    >
                </div>
                
                <div class="mb-3">
                    <label for="icon-select-widget" class="form-label">Иконка</label>
                    <div id="icon-select-widget" class="icon-select-widget">
                        <!-- Выбранная иконка -->
                        <div class="selected-icon">
                            <span>Выберите иконку</span>
                        </div>
                        <!-- Опции иконок будут загружены динамически -->
                        <div class="icon-options">
                            <!-- Опции будут добавлены через JavaScript -->
                        </div>
                    </div>
                </div>

<!--                <div class="mb-3">-->
<!--                    <label for="logo" class="form-label">Логотип точки</label>-->
<!--                    <input type="file" class="form-control" id="logo">-->
<!--                </div>-->
                
                <div class="mb-3">
                    <label class="form-label">Изображения</label>
                    <div id="drop-area" class="drop-area">
                        <p>Перетащите сюда изображения или нажмите для выбора</p>
                        <input type="file" id="images" name="images" multiple accept="image/*" style="display: none;">
                    </div>
                    <div id="gallery"></div>
                </div>
                ${imagesArray.length > 0 ? `
                    <div class="mb-3">
                        <label class="form-label"><i>Текущие</i></label>
                        <div id="current-images-gallery" class="current-images-gallery">
                            ${imagesArray.map(img => `
                                <div class="img-wrapper current-img-wrapper" data-image-id="${img.id}">
                                    <img src="${API_URL}${img.image}" class="img-fluid mb-2 preview-img" alt="Current Image" style="max-height: 150px;">
                                    <button type="button" class="remove-current-btn" aria-label="Удалить изображение">&times;</button>
                                </div>
                            `).join('')}
                        </div>
                    </div>
                ` : ''}

                <div class="mb-3">
                    <label for="point-description" class="form-label">Описание</label>
                    <textarea class="form-control" id="point-description" rows="3">${feature.properties.description || ''}</textarea>
                </div>
                <div class="mb-3">
                <label for="short-description" class="form-label">Краткое описание</label>
                <input type="text" class="form-control" id="short-description" 
                    value="${feature.properties.short_description || ''}" placeholder="Введите краткое описание">
                </div>
    
                <div class="mb-3">
                    <label for="json-description" class="form-label">JSON описание</label>
                    <textarea class="form-control" id="json-description" rows="3" placeholder="Введите JSON описание">${feature.properties.json_description || ''}</textarea>
                </div>
                <div class="mb-3">
                    <label for="address" class="form-label">Адрес</label>
                    <input type="text" class="form-control" id="address" placeholder="Введите адрес" 
                        value="${feature.properties.address || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="contact-number" class="form-label">Контактный номер</label>
                    <input type="text" class="form-control" id="contact-number" placeholder="Введите контактный номер" 
                        value="${(contactInfo.phone_numbers || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="whatsapp" class="form-label">WhatsApp</label>
                    <input type="text" class="form-control" id="whatsapp" placeholder="Введите WhatsApp" 
                        value="${(contactInfo.whatsapp_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="viber" class="form-label">Viber</label>
                    <input type="text" class="form-control" id="viber" placeholder="Введите Viber" 
                        value="${(contactInfo.viber_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="telegram" class="form-label">Telegram</label>
                    <input type="text" class="form-control" id="telegram" placeholder="Введите Telegram" 
                        value="${(contactInfo.telegram_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="instagram" class="form-label">Instagram</label>
                    <input type="text" class="form-control" id="instagram" placeholder="Введите Instagram" 
                        value="${(contactInfo.instagram_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="facebook" class="form-label">Facebook</label>
                    <input type="text" class="form-control" id="facebook" placeholder="Введите Facebook" 
                        value="${(contactInfo.facebook_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="line" class="form-label">Line</label>
                    <input type="text" class="form-control" id="line" placeholder="Введите Line" 
                        value="${(contactInfo.line_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="website" class="form-label">Ссылка на сайт</label>
                    <input type="url" class="form-control" id="website" placeholder="Введите URL сайта" 
                        value="${(contactInfo.websites || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="emails" class="form-label">Email</label>
                    <input type="email" class="form-control" id="emails" placeholder="Введите Email" 
                        value="${(contactInfo.emails || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="tripadvisor" class="form-label">Tripadvisor</label>
                    <input type="url" class="form-control" id="tripadvisor" placeholder="Введите URL Tripadvisor" 
                        value="${(contactInfo.tripadvisor_urls || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="menu" class="form-label">Меню</label>
                    <input type="url" class="form-control" id="menu" placeholder="Введите Menu url" 
                        value="${(contactInfo.menu_url || [])[0] || ''}"
                    >
                </div>
                <div class="mb-3">
                    <label for="google-maps-url" class="form-label">Ссылка на Google Maps</label>
                    <input type="url" class="form-control" id="google-maps-url" placeholder="Введите URL Google Maps" 
                        value="${feature.properties.google_maps_url || ''}"
                    >
                </div>

                <div class="mb-3">
                    <label for="working-hours-btn" class="form-label">Часы работы</label>
                    <button type="button" class="btn btn-outline-primary" id="working-hours-btn">
                        Заполнить график работы
                    </button>
                </div>
            <div id="working-hours-section" style="display: none;">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="set-all-24-hours">
                    <label class="form-check-label" for="set-all-24-hours">Поставить круглосуточно для всех</label>
                </div>
                ${['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => `
                    <div class="row mb-3">
                            <div class="col-6">
                                <label for="${day}-open" class="form-label">${day.charAt(0).toUpperCase() + day.slice(1)} - Открытие</label>
                                <input type="time" class="form-control" id="${day}-open" 
                                    value="${workingHours?.[`${day}_open`] || defaultWorkingHours?.[`${day}_open`] || ''}">
                            </div>
                            <div class="col-6">
                                <label for="${day}-close" class="form-label">${day.charAt(0).toUpperCase() + day.slice(1)} - Закрытие</label>
                                <input type="time" class="form-control" id="${day}-close" 
                                    value="${workingHours?.[`${day}_close`] || defaultWorkingHours?.[`${day}_close`] || ''}">
                            </div>
                            <div class="col-12">
                                <input type="checkbox" class="form-check-input" id="${day}-24-hours" ${workingHours?.[`is_${day}_24_hours`] ? 'checked' : ''}>
                                <label class="form-check-label" for="${day}-24-hours">Круглосуточно</label>
                            </div>
                            <div class="col-12">
                                <input type="checkbox" class="form-check-input" id="${day}-closed" 
                                    ${workingHours?.[`is_${day}_closed`] ? 'checked' : ''}
                                >
                                <label class="form-check-label" for="${day}-closed">Закрыто</label>
                            </div>
                            <div class="col-12">
                                <button type="button" class="btn btn-outline-secondary apply-for-all" data-day="${day}">
                                    Применить для всех
                                </button>
                            </div>
                        </div>
                    `).join('')}
                </div>

                <div class="mb-3">
                    <label for="tags" class="form-label">Теги</label>
                    <select class="form-control" id="tags" multiple>
                        <!-- Добавить варианты тегов -->
                    </select>
                    <input type="text" class="form-control mt-2" id="new-tag" placeholder="Введите новый тег">
                    <button type="button" class="btn btn-secondary mt-2" id="add-tag">Добавить тег</button>
                </div>

                <div class="mb-3">
                    <button type="button" class="btn btn-outline-primary" id="price-list-btn">Прайс-лист</button>
                </div>
                
                <div class="mb-3">
                    <label for="zoom-input" class="form-label">Масштаб иконки [0.1, 10]</label>
                    <input type="number" class="form-control" id="zoom-input" name="zoom" 
                        min="0.1" max="10" step="0.1" value="${feature.properties.zoom || MARKER_TYPE_ZOOM_DEFAULT}"
                    >
                </div>
                <!-- Review Button -->
                <div class="mb-3">
                    <button type="button" class="btn btn-outline-secondary" id="reviews-btn">Отзывы</button>
                </div>

                <div class="mb-3">
                    <button type="button" class="btn btn-outline-secondary" id="add-polygon-btn">Добавить полигон</button>
                </div>
                <div class="mb-3">
                    <button type="button" class="btn btn-outline-secondary" id="delete-polygon-btn">Удалить полигон</button>
                </div>
                <div class="mb-3">
                    <button type="button" class="btn btn-outline-secondary" id="add-point-btn" ${polygonExists ? '' : 'disabled'}>
                        Добавить точку на полигон
                    </button>
                </div>
                <div class="mb-3">
                    <button type="button" class="btn btn-outline-secondary" id="move-point-btn">Передвинуть точку</button>
                </div>
                <button type="submit" class="btn btn-primary">${isEdit ? 'Сохранить' : 'Добавить'}</button>
                ${isEdit ? `
                    <button type="button" class="btn btn-danger" id="delete-point-btn">Удалить</button>
                ` : ''}
            </form>
        </div>
    `;
}


function initializeMapDrawing() {
    if (!draw) {
        draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true
            }
        });
        map.addControl(draw);
    }

    // Добавьте необходимые обработчики событий
}


function initializeEventListeners(feature, isEdit) {
    // Обработчик для кнопки "Добавить полигон"
    document.getElementById('add-polygon-btn').addEventListener('click', handleAddPolygon);

    document.getElementById('add-point-btn').addEventListener('click', handleAddChildFeature);

        // Обработчик для кнопки "Удалить полигон"
    document.getElementById('delete-polygon-btn').addEventListener('click', () => handleDeletePolygon(feature));

    // Обработчик для кнопки "Прайс-лист"
    document.getElementById('price-list-btn').addEventListener('click', () => initializePriceListModal(feature));

    // Обработчик для кнопки "Отзывы"
    document.getElementById('reviews-btn').addEventListener('click', () => displayReviewsModal(feature.properties.id));

    document.getElementById('move-point-btn').addEventListener('click', () => handleMovePoint(feature));

    document.getElementById('working-hours-btn').addEventListener('click', toggleWorkingHoursSection);

    document.getElementById('add-tag').addEventListener('click', handleAddTag);


    const zoomInput = document.getElementById('zoom-input');
    if (zoomInput) {
        zoomInput.addEventListener('input', () => handleZoomInputChange(zoomInput, feature));
    }

    if (isEdit) {
        document.getElementById('delete-point-btn').addEventListener('click', () => handleDeletePoint(feature));
    }

    // Инициализация загрузки изображений
    initializeImageUpload(feature, isEdit);
    initializeWorkingHoursFields();

    // Обработчик отправки формы
    const editPointForm = document.getElementById('edit-point-form');
    if (editPointForm) {
        editPointForm.addEventListener('submit', async function (event) {
            event.preventDefault();
            await handleFormSubmission(event, feature, isEdit);
        });
    }
}

function handleAddPolygon() {
    const allPolygons = draw.getAll().features.filter(feature => feature.geometry.type === 'Polygon');
    if (allPolygons.length > 0) {
        draw.deleteAll(); // Удаляет все существующие полигоны
    }

    allowAddingPoints = false; // Запрещаем добавление точек
    setupMapClickEvent(map);
    draw.changeMode('draw_polygon'); // Активируем режим рисования полигона


    map.on('draw.create', (event) => {
        const polygonData = event.features[0]; // Получаем данные созданного полигона
        console.log(polygonData); // Можете сохранить это в вашем состоянии или отправить на сервер
        savePolygonToForm(polygonData);

        polygonModified = true;

        // Устанавливаем небольшой таймер перед разрешением добавления точек
        setTimeout(() => {
            allowAddingPoints = true; // Разрешаем добавление точек
            setupMapClickEvent(map); // Обновляем обработчик кликов
        }, 100); // Таймер в 100 мс для завершения текущей обработки
    });
}

function savePolygonToForm(polygon) {
    const coordinates = polygon.geometry.coordinates;
    const polygonString = JSON.stringify(coordinates); // Если хотите сохранить в строковом формате

    // Здесь вы можете сохранить координаты в скрытое поле формы или где угодно
    const polygonInput = document.createElement('input');
    polygonInput.type = 'hidden';
    polygonInput.name = 'polygon'; // Название вашего поля
    polygonInput.value = polygonString; // Сохраняем координаты
    document.getElementById('edit-point-form').appendChild(polygonInput);
}

function handleAddChildFeature() {
    if (!polygonExists) {
        alert('Полигон не существует, добавьте полигон перед добавлением точки.');
        return;
    }

    // Активируем режим добавления точки на полигон
    enableAddPointOnPolygonMode();
}

function enableAddPointOnPolygonMode() {
    // Изменяем курсор, чтобы показать режим добавления точки
    map.getCanvas().style.cursor = 'crosshair';
    alert('Кликните на полигон, чтобы добавить точку.');

    // Добавляем однократный обработчик клика по карте
    allowAddingPoints = false; // Запрещаем добавление точек
    setupMapClickEvent(map);
    map.once('click', onMapClickAddPointOnPolygon);
}

async function onMapClickAddPointOnPolygon(e) {
    const clickedPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);

    // Проверяем, существует ли полигон
    if (!feature.polygon || feature.polygon.coordinates.length === 0) {
        alert('Полигон не существует.');
        return;
    }

    // Создаём объект полигона для Turf.js
    const polygon = turf.polygon(feature.polygon.coordinates);

    // Проверяем, находится ли точка внутри полигона
    const isInside = turf.booleanPointInPolygon(clickedPoint, polygon);

    if (isInside) {
        // Добавляем маркер на карту
        const marker = new mapboxgl.Marker({ color: 'red' })
            .setLngLat([e.lngLat.lng, e.lngLat.lat])
            .addTo(map);

        // Сохраняем координаты точки в скрытое поле формы
        let childPointInput = document.querySelector('input[name="child_point"]');
        if (!childPointInput) {
            childPointInput = document.createElement('input');
            childPointInput.type = 'hidden';
            childPointInput.name = 'child_point';
            document.getElementById('edit-point-form').appendChild(childPointInput);
        }

        childPointInput.value = JSON.stringify([e.lngLat.lng, e.lngLat.lat]);


        // Отправляем данные точки на сервер через AJAX
        // try {
        //     const response = await fetch(`${API_URL}/api/points/${feature.properties.id}/add_child_point/`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': `Token ${API_ADMIN_TOKEN}`,
        //         },
        //         body: JSON.stringify({
        //             coordinates: [e.lngLat.lng, e.lngLat.lat],
        //             // Добавьте другие необходимые поля, если они требуются
        //         })
        //     });
        //     console.log(await response.json());
        //
        //     if (response.ok) {
        //         const childPoint = await response.json();
        //         alert('Точка успешно добавлена на полигон.');
        //
        //         // При необходимости, вы можете сохранить ID точки или другую информацию
        //         // Например, добавить её в список существующих точек на полигоне
        //     } else {
        //         const errorData = await response.json();
        //         console.error('Ошибка при добавлении точки на полигон:', errorData);
        //         alert('Произошла ошибка при добавлении точки на полигон.');
        //     }
        // } catch (error) {
        //     console.error('Ошибка при добавлении точки на полигон:', error);
        //     alert('Произошла ошибка при добавлении точки на полигон.');
        // }

        // Сбрасываем курсор
        map.getCanvas().style.cursor = '';
    } else {
        alert('Выбранная точка не находится внутри полигона. Пожалуйста, выберите точку внутри полигона.');
        // Повторно активируем режим добавления точки, чтобы пользователь мог попробовать снова
        enableAddPointOnPolygonMode();
    }
}


function handleDeletePolygon(feature) {
    const polygonFeatures = feature.polygon;


    if (polygonFeatures.coordinates.length > 0) {
        clearPolygon();
        draw.deleteAll(); // Удаляет все существующие полигоны
        console.log('Полигон удален с карты.');
    }

    // Очищаем данные полигона из feature
    delete feature.polygon;
    console.log('Данные полигона удалены из объекта feature.');

    // Очищаем скрытое поле формы, если оно существует
    const polygonInput = document.querySelector('input[name="polygon"]');
    console.log(polygonInput);
    if (polygonInput) {
        polygonInput.value = '';
        console.log('Скрытое поле формы для полигона очищено.');
    }
    polygonDeleted = true;

    alert('Полигон успешно удален.');
}


function initializeImageUpload(feature, isEdit) {
    const imagesArray = feature.properties.images || [];
    const dropArea = document.getElementById('drop-area');
    const imagesInput = document.getElementById('images');
    const gallery = document.getElementById('gallery');

    // Открытие файлового диалога при клике на область
    dropArea.addEventListener('click', () => {
        imagesInput.click();
    });

    // Предотвращение стандартных действий браузера
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false);
    });

    function preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    // Добавление класса при наведении
    ['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, () => dropArea.classList.add('hover'), false);
    });

    ['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, () => dropArea.classList.remove('hover'), false);
    });

    // Обработка сброса файлов
    dropArea.addEventListener('drop', handleDrop, false);

    function handleDrop(e) {
        const dt = e.dataTransfer;
        const files = dt.files;
        handleFiles(files);
    }

    // Обработка выбора файлов через диалог
    imagesInput.addEventListener('change', (e) => {
        handleFiles(e.target.files);
    });

    function handleFiles(files) {
        files = [...files];

        // Очистить галерею, если загружены новые файлы через выбор из файловой системы
        if (event.type === 'change') {
            gallery.innerHTML = '';
            imagesInput.value = ''; // Очищаем файлы в input перед добавлением новых
        }

        files.forEach(previewFile);

        // Добавляем файлы к input
        const dataTransfer = new DataTransfer();
        [...imagesInput.files, ...files].forEach(file => dataTransfer.items.add(file));
        imagesInput.files = dataTransfer.files;
    }


    function previewFile(file) {
        if (!file.type.startsWith('image/')) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            // Создаём обёртку для изображения и кнопки удаления
            const imgWrapper = document.createElement('div');
            imgWrapper.classList.add('img-wrapper');

            const img = document.createElement('img');
            img.src = reader.result;
            img.classList.add('preview-img');

            const removeBtn = document.createElement('button');
            removeBtn.classList.add('remove-btn');
            removeBtn.setAttribute('type', 'button'); // Чтобы кнопка не сабмитила форму
            removeBtn.setAttribute('aria-label', 'Удалить изображение');
            removeBtn.innerHTML = '&times;'; // Крестик

            // Добавляем обработчик для удаления изображения
            removeBtn.addEventListener('click', () => {
                removeFile(file, imgWrapper);
            });

            imgWrapper.appendChild(img);
            imgWrapper.appendChild(removeBtn);
            gallery.appendChild(imgWrapper);
        };
    }

    function removeFile(file, imgWrapper) {
        // Удаляем изображение из галереи
        gallery.removeChild(imgWrapper);

        // Удаляем файл из input
        const dt = new DataTransfer();
        const files = Array.from(imagesInput.files);

        // Фильтруем файлы, исключая удалённый
        files.forEach(f => {
            if (f !== file) {
                dt.items.add(f);
            }
        });

        imagesInput.files = dt.files;
    }

    // Обработка удаления текущих изображений
    if (isEdit && imagesArray.length > 0) {
        const currentImagesGallery = document.getElementById('current-images-gallery');

        // Функция для обработки удаления текущих изображений
        function handleRemoveCurrentImage(event) {
            const button = event.target;
            const imgWrapper = button.closest('.current-img-wrapper');
            const imageId = imgWrapper.getAttribute('data-image-id');

            if (imageId) {
                // Добавляем ID изображения в список для удаления
                imagesToDelete.push(imageId);
                console.log(imagesToDelete)
                // Удаляем изображение из DOM
                imgWrapper.remove();
            }
        }

        // Добавляем обработчики событий для кнопок удаления текущих изображений
        const removeCurrentBtns = document.querySelectorAll('.remove-current-btn');
        removeCurrentBtns.forEach(btn => {
            btn.addEventListener('click', handleRemoveCurrentImage);
        });
    }
}

function initializeIconWidget(feature) {
    function handleIconSelect(icon) {
        console.log('Выбрана иконка:', icon);
        feature.properties.marker_type = icon.name;
    }

    initializeIconSelectWidget(iconList, handleIconSelect, feature.properties.marker_type);
}

async function populateFormData(feature) {
    await getTags();
    const selectedTags = feature.properties.tags || [];

    const tagsSelect = document.getElementById('tags');
    selectedTags.forEach(tagName => {
        const option = Array.from(tagsSelect.options).find(opt => opt.text === tagName);
        if (option) option.selected = true;
    });

    const categories = await populateCategories();
    const selectedCategory = categories.find(cat => cat.name === feature.properties.category);
    const selectedSubcategory = selectedCategory ? selectedCategory.subcategories.find(subcat => subcat.name === feature.properties.subcategory) : null;
    populateFormCategories(categories, selectedCategory, selectedSubcategory);
}


function initializeWorkingHoursFields() {
    function toggleDayFields(day, is24Hours, isClosed) {
        const openField = document.getElementById(`${day}-open`);
        const closeField = document.getElementById(`${day}-close`);
        const day24HoursCheckbox = document.getElementById(`${day}-24-hours`);

        if (isClosed) {
            openField.disabled = true;
            closeField.disabled = true;
            day24HoursCheckbox.disabled = true;
        } else if (is24Hours) {
            openField.disabled = true;
            closeField.disabled = true;
            day24HoursCheckbox.disabled = false;
        } else {
            openField.disabled = false;
            closeField.disabled = false;
            day24HoursCheckbox.disabled = false;
        }
    }

    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    days.forEach(day => {
        const day24HoursCheckbox = document.getElementById(`${day}-24-hours`);
        const dayClosedCheckbox = document.getElementById(`${day}-closed`);

        toggleDayFields(day, day24HoursCheckbox.checked, dayClosedCheckbox.checked);

        day24HoursCheckbox.addEventListener('change', () => {
            toggleDayFields(day, day24HoursCheckbox.checked, dayClosedCheckbox.checked);
        });

        dayClosedCheckbox.addEventListener('change', () => {
            toggleDayFields(day, day24HoursCheckbox.checked, dayClosedCheckbox.checked);
        });

        // Обработчик для кнопки "Применить для всех"
        const applyForAllButton = document.querySelector(`.apply-for-all[data-day="${day}"]`);
        if (applyForAllButton) {
            applyForAllButton.addEventListener('click', () => {
                const openTime = document.getElementById(`${day}-open`).value;
                const closeTime = document.getElementById(`${day}-close`).value;
                const is24HoursChecked = day24HoursCheckbox.checked;
                const isClosedChecked = dayClosedCheckbox.checked;

                days.forEach(d => {
                    document.getElementById(`${d}-open`).value = openTime;
                    document.getElementById(`${d}-close`).value = closeTime;
                    document.getElementById(`${d}-24-hours`).checked = is24HoursChecked;
                    document.getElementById(`${d}-closed`).checked = isClosedChecked;
                    toggleDayFields(d, is24HoursChecked, isClosedChecked);
                });
            });
        }
    });

    // Обработчик для чекбокса "Поставить круглосуточно для всех"
    const setAll24HoursCheckbox = document.getElementById('set-all-24-hours');
    if (setAll24HoursCheckbox) {
        setAll24HoursCheckbox.addEventListener('change', () => {
            const isChecked = setAll24HoursCheckbox.checked;
            days.forEach(day => {
                const day24HoursCheckbox = document.getElementById(`${day}-24-hours`);
                const dayClosedCheckbox = document.getElementById(`${day}-closed`);

                day24HoursCheckbox.checked = isChecked;
                if (isChecked) {
                    dayClosedCheckbox.checked = false;
                }

                toggleDayFields(day, isChecked, dayClosedCheckbox.checked);
            });
        });
    }
}

function handleZoomInputChange(zoomInput, feature) {
    const MARKER_TYPE_ZOOM_DEFAULT = 1.0;

    let value = parseFloat(zoomInput.value);
    if (isNaN(value)) {
        value = MARKER_TYPE_ZOOM_DEFAULT;
    }
    value = Math.min(Math.max(value, 0.1), 10);
    zoomInput.value = value.toFixed(2);
    feature.properties.zoom = value;
}

async function handleAddTag() {
    const newTagInput = document.getElementById('new-tag');
    const newTag = newTagInput.value.trim();

    if (!newTag) {
        alert('Пожалуйста, введите тег.');
        return;
    }

    try {
        const createdTag = await createTag(newTag);

        const tagsSelect = document.getElementById('tags');
        const option = document.createElement('option');
        option.value = createdTag.id;
        option.textContent = createdTag.name;
        option.selected = true;

        newTagInput.value = '';
        alert('Тег успешно добавлен.');
    } catch (error) {
        alert(`Произошла ошибка при добавлении тега: ${error.message}`);
    }
}

function toggleWorkingHoursSection() {
    const section = document.getElementById('working-hours-section');
    const isVisible = section.style.display === 'block';
    section.style.display = isVisible ? 'none' : 'block';
    document.getElementById('working-hours-btn').textContent = isVisible ? 'Заполнить график работы' : 'Скрыть график работы';
}

function handleMovePoint(feature) {
    if (!isMovingPoint) {
        isMovingPoint = true;
        map.getCanvas().style.cursor = 'crosshair';
        alert('Кликните на карте, чтобы установить новое местоположение точки.');
        map.on('click', onMapClickForMovePoint);
        setupMapClickEvent(map)
    }

    function onMapClickForMovePoint(e) {
        feature.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];

        const featureIndex = placesData.features.findIndex(f => f.properties.id === feature.properties.id);
        if (featureIndex !== -1) {
            placesData.features[featureIndex].geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];
        }

        map.getSource('places').setData(placesData);

        // Создаем новый маркер на новом месте
        tempMarker = new mapboxgl.Marker()
            .setLngLat([e.lngLat.lng, e.lngLat.lat])
            .addTo(map);

        isMovingPoint = false;
        setupMapClickEvent(map)

        // Сбрасываем курсор
        map.getCanvas().style.cursor = '';

        if (tempMarker) {
            tempMarker.remove();
        }
    }
}

async function displayReviewsModal(featureId) {
    const reviewsModalElement = document.getElementById('reviewsModal');
    const reviewsModal = new Modal(reviewsModalElement);
    reviewsModal.show();
    await displayReviews(featureId);

    document.getElementById('save-review').addEventListener('click', async () => {
        const rating = document.getElementById('review-rating').value;
        const comment = document.getElementById('review-comment').value.trim();
        const reviewId = document.getElementById('save-review').getAttribute('data-review-id');

        if (!comment) {
            alert('Введите комментарий.');
            return;
        }

        const reviewData = {
            rating: parseInt(rating),
            comment: comment
        };

        try {
            if (reviewId) {
                // Обновляем существующий отзыв
                await updateReview(featureId, reviewId, reviewData);
                document.getElementById('save-review').removeAttribute('data-review-id');
            } else {
                // Сохраняем новый отзыв
                await createReview(featureId, reviewData);
            }
            await displayReviews(featureId); // Обновляем список отзывов
            document.getElementById('review-comment').value = '';
            document.getElementById('review-rating').value = '5';
        } catch (error) {
            alert('Ошибка при сохранении отзыва.');
            console.error(error);
        }
    });
}


async function initializePriceListModal(feature){
    const priceListModalElement = document.getElementById('priceListModal');
    const priceListModal = new Modal(priceListModalElement);


    const modalPriceListSection = document.getElementById('modal-price-list-section');
    const hasProducts = feature.properties.price_list && feature.properties.price_list.length > 0;

    modalPriceListSection.innerHTML = `
        ${hasProducts ? `
            <div class="row mb-3 headers">
                <div class="col-4"><strong>Название</strong></div>
                <div class="col-3"><strong>Цена</strong></div>
                <div class="col-2"><strong>Количество</strong></div>
                <div class="col-2"><strong>Валюта</strong></div>
                <div class="col-1"></div>
            </div>
        ` : ''}
        <div id="product-list">
            ${hasProducts ? feature.properties.price_list.map((item, index) => `
                <div class="row mb-3 align-items-center" data-index="${index}">
                    <div class="col-4">
                        <input type="text" class="form-control" placeholder="..." value="${item.product_name}">
                    </div>
                    <div class="col-3">
                        <input type="number" step="0.01" class="form-control" placeholder="0" value="${item.price}">
                    </div>
                    <div class="col-2">
                        <input type="number" step="1" class="form-control" placeholder="1" value="${item.quantity}">
                    </div>
                    <div class="col-2">
                        <input type="text" class="form-control" placeholder="THB" maxlength="3" minlength="3" 
                            value="${item.currency}">
                    </div>
                    <div class="col-1 text-center">
                        <button type="button" class="btn btn-danger btn-sm remove-product-btn">&times;</button>
                    </div>
                </div>
            `).join('') : ''}
        </div>
        <div id="add-product-btn-container" class="mb-3 mt-3">
            <button type="button" class="btn btn-outline-primary" id="modal-add-product-btn">Добавить товар</button>
        </div>
    `;

    // Добавляем обработчики событий для кнопок "Удалить"
    modalPriceListSection.querySelectorAll('.remove-product-btn').forEach(button => {
        button.addEventListener('click', (event) => {
            const row = event.target.closest('.row');
            row.remove();
        });
    });

    // Обработчик для кнопки "Добавить товар" внутри модального окна
    document.getElementById('modal-add-product-btn').addEventListener('click', () => {

        const priceListSection = document.getElementById('modal-price-list-section');

        if (!priceListSection.querySelector('.row.mb-3.headers')) {
            const headersHTML = `
                <div class="row mb-3 headers">
                    <div class="col-4"><strong>Название</strong></div>
                    <div class="col-3"><strong>Цена</strong></div>
                    <div class="col-2"><strong>Количество</strong></div>
                    <div class="col-2"><strong>Валюта</strong></div>
                    <div class="col-1"></div>
                </div>
            `;
            priceListSection.insertAdjacentHTML('afterbegin', headersHTML);
        }

        const newProductIndex = priceListSection.querySelectorAll('.row.mb-3').length;
        const newProductHTML = `
            <div class="row mb-3 align-items-center" data-index="${newProductIndex}">
                <div class="col-4">
                    <input type="text" class="form-control" placeholder="...">
                </div>
                <div class="col-3">
                    <input type="number" step="0.01" class="form-control" placeholder="0">
                </div>
                <div class="col-2">
                    <input type="number" step="1" class="form-control" placeholder="1">
                </div>
                <div class="col-2">
                    <input type="text" class="form-control" placeholder="THB" maxlength="3" minlength="3">
                </div>
                <div class="col-1 text-center">
                    <button type="button" class="btn btn-danger btn-sm remove-product-btn">&times;</button>
                </div>
            </div>
        `;

        const addProductBtnContainer = document.getElementById('add-product-btn-container');
        addProductBtnContainer.insertAdjacentHTML('beforebegin', newProductHTML);

        // Добавляем обработчик события для новой кнопки "Удалить"
        const newRow = priceListSection.querySelector('.row.mb-3:last-child');
        const removeButton = newRow.querySelector('.remove-product-btn');
        removeButton.addEventListener('click', (event) => {
            const row = event.target.closest('.row');
            row.remove();

            const productRows = priceListSection.querySelectorAll('.row.mb-3.align-items-center');
            if (productRows.length === 0) {
                const headers = priceListSection.querySelector('.row.mb-3.headers');
                if (headers) headers.remove();
            }
        });
    });

    // Показываем модальное окно
    priceListModal.show();

    // Обработчик для кнопки "Сохранить" в модальном окне
    document.getElementById('save-price-list-btn').addEventListener('click', () => {

        const priceListSection = document.getElementById('modal-price-list-section');
        const productRows = priceListSection.querySelectorAll('.row.mb-3.align-items-center');
        const newPriceList = [];
        let hasError = false;

        productRows.forEach(row => {
            const productName = row.querySelector('input[placeholder="..."]').value.trim();
            const priceValue = row.querySelector('input[placeholder="0"]').value;
            const price = parseFloat(priceValue);
            let quantity = row.querySelector('input[placeholder="1"]').value.trim();
            let currency = row.querySelector('input[placeholder="THB"]').value.trim();

            if (!productName || isNaN(price)) {
                hasError = true;
                return;
            }

            // Устанавливаем значения по умолчанию
            if (!quantity) {
                quantity = '1';
            }
            if (!currency) {
                currency = 'THB';
            }

            // Убедимся, что количество — целое число
            quantity = parseInt(quantity);
            if (isNaN(quantity)) {
                quantity = 1;
            }

            // Проверяем, что валюта состоит из 3 символов
            if (currency.length !== 3) {
                hasError = true;
                return;
            }

            newPriceList.push({
                product_name: productName,
                price: price,
                quantity: quantity,
                currency: currency
            });
        });

        if (hasError) {
            alert('Пожалуйста, заполните все поля корректно. Название и цена обязательны. Валюта должна состоять из 3 символов.');
            return;
        }

        feature.properties.price_list = newPriceList;
        priceListModal.hide();
    });
}

async function handleDeletePoint(feature) {
    const pointId = feature.properties.id;
    if (confirm('Вы уверены, что хотите удалить эту точку?')) {
        try {
            placesData.features = placesData.features.filter(feat => feat.properties.id !== pointId);
            map.getSource('places').setData(placesData);

            hideSidebar();
            setDefaultSidebarContent();

            alert('Точка успешно удалена.');

            await deletePoint(pointId);
        } catch (error) {
            console.error('Ошибка при удалении точки:', error);
            alert('Произошла ошибка при удалении точки.');
        }
    }
}

async function handleFormSubmission(event, feature, isEdit) {
    event.preventDefault();
    const selectedTags = Array.from(document.getElementById('tags').selectedOptions).map(option => option.value);

    let polygonValue = null;
    const polygonInput = document.querySelector('input[name="polygon"]');
    if (polygonInput) {
        try {
            polygonValue = JSON.parse(polygonInput.value);
        } catch (error) {
            console.error("Ошибка парсинга значения полигона:", error);
        }
    }

    const imagesInput = document.getElementById('images');
    const images = Array.from(imagesInput.files);

    // Извлекаем остальные данные из формы
    const newContactNumber = document.getElementById('contact-number').value.trim();
    const newWhatsApp = document.getElementById('whatsapp').value.trim();
    const newViber = document.getElementById('viber').value.trim();
    const newTelegram = document.getElementById('telegram').value.trim();
    const newInstagram = document.getElementById('instagram').value.trim();
    const newFacebook = document.getElementById('facebook').value.trim();
    const newEmail = document.getElementById('emails').value.trim();
    const newMenu = document.getElementById('menu').value.trim();
    const newLine = document.getElementById('line').value.trim();
    const newTripadvisor = document.getElementById('tripadvisor').value.trim();
    const newWebsite = document.getElementById('website').value.trim();
    const googleMapsUrls = document.getElementById('google-maps-url').value.trim();

    const newName = document.getElementById('point-name').value.trim();
    const newDescription = document.getElementById('point-description').value.trim();
    const shortDescription = document.getElementById('short-description').value;
    const jsonDescription = document.getElementById('json-description').value;
    // TODO юзается вообще?
    const newAddress = document.getElementById('address').value.trim();
    const newZoom = document.getElementById('zoom-input').value;
    const workingHoursData = {};
    ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
        const openTime = document.getElementById(`${day}-open`).value;
        const closeTime = document.getElementById(`${day}-close`).value;

        workingHoursData[`${day}_open`] = openTime === '' ? null : openTime;
        workingHoursData[`${day}_close`] = closeTime === '' ? null : closeTime;
        workingHoursData[`is_${day}_24_hours`] = document.getElementById(`${day}-24-hours`).checked;
        workingHoursData[`is_${day}_closed`] = document.getElementById(`${day}-closed`).checked;
    });

    const categorySelect = document.getElementById('category-select');
    const subcategorySelect = document.getElementById('subcategory-select');

    const selectedCategoryName = categorySelect.value;
    const selectedSubcategoryName = subcategorySelect.value;

    const updatedPointData = {
        name: newName,
        description: newDescription,
        shortDescription: shortDescription,
        jsonDescription: jsonDescription,
        address: newAddress,
        images: images,
        // logo: logo,
        // TODO продумать логику чтоб брался из подкатегории
        category: selectedCategoryName,
        subcategory: selectedSubcategoryName,
        tags: selectedTags,
        contactInfo: {
            phoneNumbers: newContactNumber !== '' ? [newContactNumber] : [],
            whatsappUrls: newWhatsApp ? [newWhatsApp] : [],
            viberUrls: newViber ? [newViber] : [],
            telegramUrls: newTelegram ? [newTelegram] : [],
            instagramUrls: newInstagram ? [newInstagram] : [],
            facebookUrls: newFacebook ? [newFacebook] : [],
            email: newEmail ? [newEmail] : [],
            menu: newMenu ? [newMenu] : [],
            tripadvisorUrls: newTripadvisor ? [newTripadvisor] : [],
            lineUrls: newLine ? [newLine] : [],
            websites: newWebsite ? [newWebsite] : [],
        },
        workingHours: workingHoursData,
        priceList: feature.properties.price_list || [],
        coordinates: feature.geometry.coordinates,
        googleMapsUrls: googleMapsUrls,
        zoom: newZoom,
    };

    if (polygonDeleted) {
        // Если полигон был удалён, устанавливаем его как null
        updatedPointData.polygon = null;
    } else if (polygonModified) {
        // Если полигон был изменён или создан, устанавливаем новое значение
        updatedPointData.polygon = polygonValue;
    } else if (feature.polygon) {
        updatedPointData.polygon = feature.polygon.coordinates;
    }

    if (feature.properties.markerType !== 'Нет иконки') {
        updatedPointData.markerType = feature.properties.marker_type;
    }

    // Обработка создания или обновления точки
    try {
        let responsePoint;
        if (isEdit) {
            responsePoint = await updatePoint(feature.properties.id, updatedPointData);
        } else {
            responsePoint = await createPoint(updatedPointData);
        }

        // Обработка удаления изображений, если это редактирование
        if (isEdit && imagesToDelete && imagesToDelete.length > 0) {
            console.log(imagesToDelete)
            await deleteImages(imagesToDelete, feature.properties.id);
        }

        // Обновляем данные карты и интерфейса
        const placesData = await loadPlacesData();
        savePlacesData(placesData);
        updatePlacesLayerData(map, placesData);

        hideSidebar();
        setDefaultSidebarContent();
        const allPolygons = draw.getAll().features.filter(feature => feature.geometry.type === 'Polygon');
        if (allPolygons.length > 0) {
            draw.deleteAll();
        }
        polygonDeleted = false;
        polygonModified = false;

        alert(isEdit ? 'Точка успешно обновлена.' : 'Точка успешно создана.');
    } catch (error) {
        console.error(isEdit ? 'Ошибка при обновлении точки:' : 'Ошибка при создании точки:', error);
        alert(isEdit ? 'Произошла ошибка при обновлении точки.' : 'Произошла ошибка при создании точки.');
    }
}
