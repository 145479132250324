import mapboxgl from 'mapbox-gl';
import {placeLayerId, updatePlacesLayerData} from './layers';
// import {openEditEvent, openEditPoint} from "../index";
import {openEditEvent} from "../index";
import {getPlacesData, loadPlacesData} from "./data";
import {populateEvent} from "./api/events";
import {clearPolygon, populatePoint} from "./api/points";
import {openEditPoint} from "./openEditPoint";

// Функция для создания и обновления попапа
export function updatePopup(popup, coordinates, id, name, description, image) {
    const popupContent = `
        <div class="popup-content">
            <div class="card" style="width: 18rem; max-width: 100%;">
                ${image ? `<img src="${image}" class="card-img-top img-fluid" alt="Image" style="object-fit: cover; max-height: 150px;">` : ''}
                <div class="card-body">
                    <h5 class="card-title">ID: ${id}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">${name}</h6>
                    <p class="card-text">${description}</p>
                </div>
            </div>
        </div>
    `;

    popup.setLngLat(coordinates).setHTML(popupContent);
}

// Функция для добавления попапа на карту
export function addPopupToMap(map, popup, coordinates, id, name, description, image) {
    updatePopup(popup, coordinates, id, name, description, image);
    if (!popup.isOpen()) {
        popup.addTo(map);
    }
}


export async function updatePointsData(map) {
    const placesData = await getPlacesData();
    updatePlacesLayerData(map, placesData);
}


export async function showEditForm(feature) {
    // Вызов экспортированной функции openEditForm
    clearPolygon();
    if (feature.properties.type === 'Event') {
        feature = await populateEvent(feature.properties.id);
        await openEditEvent(feature); // Вызываем функцию для точки
    } else {
        feature = await populatePoint(feature.properties.id);
        await openEditPoint(feature);
    }
}

// Функция для удаления попапа
export function removePopup(popup) {
    popup.remove();
}

let mouseEnterHandler = null;
let mouseLeaveHandler = null;

// Функция для добавления интерактивных функций на карту
export function addInteractiveFunctions(map) {

    // Удаляем существующие обработчики событий, чтобы избежать дублирования
    if (mouseEnterHandler) {
        map.off('mouseenter', placeLayerId, mouseEnterHandler);
    }
    if (mouseLeaveHandler) {
        map.off('mouseleave', placeLayerId, mouseLeaveHandler);
    }

    // Создание пустого попапа
    const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
    });

    // Обработчик наведения на метку
    mouseEnterHandler = function (e) {
        map.getCanvas().style.cursor = 'pointer';

        const feature = e.features[0];
        const coordinates = feature.geometry.coordinates.slice();
        const id = feature.properties.id;
        const name = feature.properties.name;
        const description = feature.properties.description;
        const image = feature.properties.image;

        // Корректировка координат
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        addPopupToMap(map, popup, coordinates, id, name, description, image);
    };
    map.on('mouseenter', placeLayerId, mouseEnterHandler);

    // Обработчик ухода курсора с метки
    mouseLeaveHandler = function () {
        map.getCanvas().style.cursor = '';
        removePopup(popup);
    };
    map.on('mouseleave', placeLayerId, mouseLeaveHandler);
}
