import { API_ADMIN_TOKEN, API_URL } from "./config";

// Функция для получения стилей карты
export const fetchMapStyles = async () => {
    const response = await apiRequest(`${API_URL}/api/styles/`, {
        method: "GET",
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

// Функция для получения стиля карты пользователя
export const fetchUserMapStyle = async () => {
    const response = await apiRequest(`${API_URL}/api/users/me/`, {
        method: 'GET',
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

// Функция для обновления выбранного стиля карты
export const updateUserMapStyle = async (newStyleId) => {
    const response = await apiRequest(`${API_URL}/api/users/me/`, {
        method: 'PATCH',
        body: JSON.stringify({ selected_map_style: newStyleId }),
    });

    const text = await response.text();
    console.log('Response status:', response.status);
    console.log('Response body:', text);

    if (!response.ok) {
        throw new Error('Failed to update map style');
    }

    try {
        return JSON.parse(text);
    } catch (error) {
        throw new Error('Failed to parse response as JSON');
    }
};
